export default function () {
    const navBarItems = [
        {
            title: 'ホーム',
            routeURL: { name: 'Home' },
        },
        {
            title: 'はじめに',
            routeURL: { name: 'Intro' },
        },
        {
            title: '生命の大親\n『大親神大生命』',
            routeURL: { name: 'Master' },
        },
        {
            title: '貴き親理のお授け\n大自然の真理法則',
            routeURL: { name: 'Doctrine' },
        },
        {
            title: '大自然界の活動',
            routeURL: { name: 'Activities' },
        },

        {
            title: '界員の声\n親感謝の言葉',
            routeURL: { name: 'Thanks' },
        },
        {
            title: 'Q&A',
            routeURL: { name: 'QA' },
        },
        {
            title: '基本セミナー\nお申し込み',
            routeURL: { name: 'Seminar' },
        },
        {
            title: 'おつなぎ徳積み',
            routeURL: { name: 'Donate' },
        },
        {
            title: 'お問い合わせ',
            routeURL: { name: 'Join' },
        },
    ]
    const navBarMobileItems = [
        {
            title: 'ホーム',
            routeURL: { name: 'Home' },
        },
        {
            title: 'はじめに',
            routeURL: { name: 'Intro' },
        },
        {
            title: '生命の大親『大親神大生命』',
            routeURL: { name: 'Master' },
        },
        {
            title: '貴き親理のお授け\n大自然の真理法則',
            routeURL: { name: 'Doctrine' },
        },
        {
            title: '大自然界の活動',
            routeURL: { name: 'Activities' },
        },

        {
            title: '界員の声・親感謝の言葉',
            routeURL: { name: 'Thanks' },
        },
        {
            title: 'Q&A',
            routeURL: { name: 'QA' },
        },
        {
            title: '基本セミナー・お申し込み',
            routeURL: { name: 'Seminar' },
        },
        {
            title: 'おつなぎ徳積み',
            routeURL: { name: 'Donate' },
        },
        {
            title: 'お問い合わせ',
            routeURL: { name: 'Join' },
        },
    ]

    return { navBarItems, navBarMobileItems }
}
