<template>
    <div id="NavBar">
        <div id="nav-bar-pc">
            <router-link id="nav-bar-logo" :to="{ name: 'Home' }">
                <img
                    v-lazy="require('@/assets/logo/horizontal-logo.png')"
                    alt="horizontal-logo"
                />
            </router-link>
            <nav class="f12" id="nav-bar-menu">
                <router-link
                    v-for="(item, index) in navBarItems"
                    :key="'nav-bar-item-' + index"
                    :to="item.routeURL"
                >
                    <p v-html="wrap(item.title)"></p>
                </router-link>
            </nav>
            <router-link
                class="f13 serif"
                id="nav-bar-login"
                :to="{ name: 'MemberSystemLogin' }"
            >
                界員ページ
            </router-link>
        </div>
        <div id="nav-bar-mobile" :class="{ isExpanded }">
            <div id="nav-bar-burger" @click="toggle">
                <i class="top"></i>
                <i class="front"></i>
                <i class="back"></i>
                <i class="bottom"></i>
                <p class="f12-mb serif">MENU</p>
            </div>
            <nav id="nav-bar-list">
                <router-link
                    id="nav-bar-list-logo"
                    @click="toggle"
                    :to="{ name: 'Home' }"
                >
                    <img
                        v-lazy="require('@/assets/logo/white-logo.png')"
                        alt="horizontal logo"
                    />
                </router-link>
                <nav class="f14-mb" id="nav-bar-list-menu">
                    <router-link
                        v-for="(item, index) in navBarMobileItems"
                        :key="'navItem' + index"
                        :to="item.routeURL"
                        @click="toggle"
                    >
                        <p v-html="wrap(item.title)"></p>
                    </router-link>
                </nav>
                <router-link
                    class="f14-mb"
                    id="nav-bar-list-login"
                    :to="{ name: 'MemberSystemLogin' }"
                    @click="toggle"
                >
                    界員ページ
                </router-link>
                <address id="nav-bar-sns">
                    <a
                        href="https://www.youtube.com/channel/UC86wUTSbA9Z25zO523GmZ5Q"
                        target="_blank"
                        ><i class="icon-sns-youtube"></i
                    ></a>
                    <a
                        href="https://www.facebook.com/daishizenkai.official/"
                        target="_blank"
                        ><i class="icon-sns-facebook"></i
                    ></a>
                    <a
                        href="https://ameblo.jp/daishizenkai-official/"
                        target="_blank"
                        ><i class="icon-sns-blog"></i
                    ></a>
                </address>
            </nav>
            <div id="nav-bar-mask" @click="toggle"></div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import getNavBarData from '../data/getNavBarData'
import { wrap } from '@/utils/common'
export default {
    name: 'NavBar',
    setup() {
        const { navBarItems, navBarMobileItems } = getNavBarData()
        const isExpanded = ref(false)
        function toggle() {
            isExpanded.value = !isExpanded.value
        }
        return { navBarItems, navBarMobileItems, isExpanded, toggle, wrap }
    },
}
</script>
<style lang="scss" scoped>
#NavBar {
    left: 0;
    top: 0;
    z-index: 300;
}
#nav-bar-pc {
    border-top: 10px solid $majorPurple;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: $shadow2;
    #nav-bar-logo {
        img {
            display: inline-block;
            width: 160px;
        }
    }
    #nav-bar-menu {
        display: flex;
        justify-content: center;
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 10px;

            color: $gray0;
            text-align: center;

            transition: 0.3s;
            border-bottom: 5px solid transparent;

            &:hover {
                color: $gray6;
            }

            &.router-link-exact-active {
                border-bottom: 4px solid $majorPurple;
            }

            p {
                line-height: 1.5em;
            }
        }
    }
    #nav-bar-login {
        @include solidButtonStyle();
    }
}
#nav-bar-mobile {
    position: fixed;
    right: 0;
    top: 0;
    #nav-bar-mask {
        display: none;
    }
    #nav-bar-burger {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background-color: rgba($majorPurple, 0.9);
        z-index: 300;
        transition: 0.3s;
        cursor: pointer;
        i {
            position: absolute;
            left: 10px;
            display: block;
            width: 30px;
            height: 2px;
            background-color: $white;
            transform-origin: center;
            transition: 0.3s;
        }
        i.top {
            top: 10px;
        }
        i.bottom {
            top: 22px;
        }
        i.top,
        i.bottom {
            opacity: 1;
        }
        i.front,
        i.back {
            top: 16px;
        }
        p {
            padding-top: 30px;
            text-align: center;
            color: $white;
        }
    }
    #nav-bar-list {
        position: absolute;
        right: -250px;
        top: 0;
        width: 250px;
        padding: 30px 20px 20px;
        background-color: rgba($majorPurple, 0.9);
        z-index: 300;
        box-sizing: border-box;
        transition: 0.3s;

        #nav-bar-list-logo {
            display: block;
            padding-bottom: 20px;
            text-align: center;
            border-bottom: 1px solid white;
            img {
                display: inline-block;
                width: 80px;
            }
        }

        #nav-bar-list-menu {
            a {
                display: block;
                color: $white;
                border-bottom: 1px solid rgba(white, 0.3);
                padding: 7px 7px;
                transition: 0.3s;

                &:hover {
                    color: $grayEF;
                }
            }
        }

        #nav-bar-list-login {
            display: block;
            margin: 20px 0 10px;
            width: 100%;
            text-align: center;
            color: $white;
            line-height: 2.4em;
            border: 1px solid $grayF;
            transition: 0.3s;

            &:hover {
                color: $grayEF;
            }
        }
    }
    #nav-bar-sns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 10px;
        a {
            position: relative;
            margin: 0 10px;
            font-size: 28px;
            line-height: 1em;
            color: $white;
            transition: 0.3s;
            cursor: pointer;
        }
    }

    &.isExpanded {
        #nav-bar-mask {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(black, 0.2);
        }
        #nav-bar-burger {
            right: 250px;
            i.top,
            i.bottom {
                opacity: 0;
            }
            i.front {
                transform: rotate(45deg);
            }
            i.back {
                transform: rotate(-45deg);
            }
        }
        #nav-bar-list {
            right: 0;
        }
    }
}

@media screen and (min-width: 1280px) {
    #NavBar {
        position: sticky;
    }
    #nav-bar-mobile {
        display: none;
        opacity: 0;
    }
    #nav-bar-pc {
        display: flex;
        opacity: 1;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        height: $navHeight;
        #nav-bar-logo {
            margin-top: 6px;
            flex: 0 0;
        }
        #nav-bar-menu {
            flex: 1 0;
            height: 100%;
            margin: 0 40px;
        }
        #nav-bar-login {
            flex: 0 0 120px;
        }
    }
}
@media screen and (max-width: 1279px) and (min-width: 768px) {
    #NavBar {
        position: sticky;
    }
    #nav-bar-mobile {
        display: none;
        opacity: 0;
    }
    #nav-bar-pc {
        display: block;
        opacity: 1;
        height: $narrowNavHeight;
        text-align: center;
        #nav-bar-logo {
            display: block;
            padding-top: 20px;
            height: 40px;
        }
        #nav-bar-menu {
            height: 50px;
        }
        #nav-bar-login {
            position: absolute;
            right: 10px;
            top: 20px;
            width: 150px;
        }
    }
}
@media screen and (max-width: 767px) {
    #NavBar {
        position: fixed;
    }
    #nav-bar-pc {
        display: none;
        opacity: 0;
    }
    #nav-bar-mobile {
        display: block;
        opacity: 1;
    }
}
</style>
